import io from 'socket.io-client';
const socket = io.connect("https://test-stagev2-server-5dc486aee288.herokuapp.com/");
import { Text } from '@react-three/drei'

var dmxchan0;

socket.on("dmxstreamtoclients", (arg) => {
  dmxchan0 = Object.values(arg)[0];
});

  function checkstream ()  { console.log(dmxchan0); }
  setInterval(checkstream, 1000)


  const Lights = () => {
    return (
      <>
       <spotLight position={[5, 5, 3]} 
                    distance={8}
                    angle={20}
                    penumbra={0}
                    decay={1}
                    power={dmxchan0}
                 />

      <Text
        scale={[.2, .2, .2]}
        color="white" // default
        anchorX="center" // default
        anchorY="middle" // default
        position={[-.8, -.8, 1.1]} 
        text={dmxchan0}
        />
    </>
    );
  };
  
  export default Lights;