import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import Lights from './LightingClient.js';

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  return (
    <mesh>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color={"white"} />
    </mesh>
  )
}

export default function App() {
  return (
    <Canvas>

      <ambientLight intensity={0} />

      <Lights />

      <Box position={[0, 0, 0]} />
      <OrbitControls />
    </Canvas>
  )
}
